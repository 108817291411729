@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  main {
    margin-left: 0 !important; /* Remove margin on mobile */
  }
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
  main {
    margin-left: 0 !important; /* Remove margin on mobile */
  }
}

@media (min-width: 769px) {
  .sidebar {
    display: block;
  }
  .sidebar.collapsed + main {
    margin-left: 80px; /* Adjust for collapsed sidebar */
  }
}

body, #root {
  min-height: 100vh;
}

#root {
  display: flex;
  flex-direction: column;
}

.logo-sidebar {
  justify-content: center;
  text-align: center;
  padding: 20px;
}