.sidebar {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 250px;
  background: #2E6C83;
  color: white;
  padding: 1.5rem;
  transition: all 0.3s ease;
  z-index: 1000;
  display: flex;
  flex-direction: column;
}

.sidebar.collapsed {
  width: 80px;
}

.sidebar-header {
  margin-bottom: 2rem;
  text-align: center;
}

.logo {
  width: 100px;
  margin-bottom: 1rem;
  transition: all 0.3s ease;
}

.logo.collapsed {
  width: 50px;
}

.branding h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.branding p {
  font-size: 0.8rem;
  opacity: 0.8;
}

.sidebar-nav {
  flex: 1;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 1rem;
  margin: 0.5rem 0;
  border-radius: 8px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.nav-item:hover {
  background: #6CE5E8;
  color: #2E6C83;
}

.nav-item.active {
  background: #6CE5E8;
  color: #2E6C83;
  font-weight: 500;
}

.nav-item svg {
  font-size: 1.5rem;
  margin-right: 1rem;
}

.nav-item.collapsed {
  justify-content: center;
}

.nav-item.collapsed svg {
  font-size: 1.8rem;
  margin: 0 auto;
}

.sidebar-footer {
  margin-top: auto;
  padding-top: 1rem;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}

.sign-out-btn {
  background: none;
  border: none;
  color: white;
  width: 100%;
  padding: 1rem;
  text-align: left;
  cursor: pointer;
  transition: all 0.3s ease;
}

.sign-out-btn:hover {
  color: #6CE5E8;
}

.toggle-btn {
  background: none;
  border: none;
  color: white;
  width: 100%;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-btn:hover {
  color: #6CE5E8;
}

.mobile-menu {
  position: fixed;
  top: 60px;
  left: 0;
  right: 0;
  bottom: 0;
  background: #2E6C83;
  z-index: 999;
  padding: 1rem;
}

.mobile-menu-enter {
  transform: translateY(-100%);
}

.mobile-menu-enter-active {
  transform: translateY(0);
  transition: transform 300ms ease-in-out;
}

.mobile-menu-exit {
  transform: translateY(0);
}

.mobile-menu-exit-active {
  transform: translateY(-100%);
  transition: transform 300ms ease-in-out;
}

.navbar-brand img {
  transition: all 0.3s ease;
}

.sidebar-header {
  align-items: center;
  justify-content: center; /* Centers the logo when expanded */
  flex-direction: column;
  display: flex;
}

.logo {
  transition: all 0.3s ease;
}

/* Expanded logo styles */
.expanded-logo {
  width: 120px; /* Adjust based on design */
  height: auto;
  display: block;
  margin: 0 auto; /* Centers the expanded logo */
}

/* Collapsed logo styles */
.collapsed-logo {
  width: 40px; /* Smaller size for collapsed state */
  height: auto;
  display: block;
  margin: 0 auto; /* Keep centered */
}

.branding {
  text-align: center;
  margin-top: 10px;
}

.sidebar.collapsed .branding {
  display: none; /* Hide branding text when collapsed */
}

.sidebar.collapsed .expanded-logo {
  display: none; /* Hide large logo */
}

.sidebar.collapsed .collapsed-logo {
  display: block; /* Show small logo */
  margin: 0 auto; /* Keep centered */
}
